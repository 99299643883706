

function iniciarCookies() {

    	//eliminamos cookies

        var path = window.location.pathname;
        //var queryString = window.location.search; Devuelve los parametros de la url
        
    
        
    if(path === '/legal/aviso-cookies' || (path === '/legal/aviso-legal') || (path === '/legal/politica-privacidad')){
        return
    }
    //Personalizadas

    setTimeout(function() {
       

    let url = "https://google.com";
    let paginaAvisoCookies = "/legal/aviso-cookies";
    let tienda = "Naweblos";
    let logo = "/build/img/templates/logo2.png";

    // variables globales
    let comprobar = true;
    let retirarConsentimiento = true;
    let fecha = new Date().getFullYear();
    //let rechazar = true;

    //crear elementos Aceptacion Cookies
    const divCuerpo = document.querySelector("BODY");
    const divBloqueo = document.createElement("DIV");
    const divCookies = document.createElement("DIV");
    const divImg = document.createElement("DIV");
    //const imgCookies = document.createElement("IMG");
    const imgLogo = document.createElement("IMG");
    const divTexto = document.createElement("DIV");
    const parrafo1 = document.createElement("P");
    const parrafo2 = document.createElement("P");
    const parrafo3 = document.createElement("P");
    const botonAceptar = document.createElement("BUTTON");
    const botonAviso = document.createElement("BUTTON");
    //const botonRechazar = document.createElement("BUTTON");



    //Crear Elemetos Widget
    const divWidget = document.createElement("DIV");


    //añadir clases
    divBloqueo.classList.add("cookies-div__bloqueo");
    divCookies.classList.add("cookies-div__principal");
    divImg.classList.add("cookies-div__imagen");
    //imgCookies.classList.add("cookies-imagen__cookie");
    imgLogo.classList.add("cookies-imagen__logo");
    divTexto.classList.add("cookies-contenedor__texto");
    parrafo1.classList.add("cookies-contenedor__parrafo1");
    parrafo2.classList.add("cookies-contenedor__parrafo2");
    parrafo3.classList.add("cookies-contenedor__parrafo3");
    botonAceptar.classList.add("cookies-contenedor__boton-aceptar");
    botonAviso.classList.add("cookies-contenedor__boton-cancelar");
    //botonRechazar.classList.add("cookies-contenedor__boton-cancelar");
    divWidget.classList.add("cookies-div__widget-svg");



    //añadir textos

    parrafo1.textContent = (`${tienda} utiliza cookies`);
    parrafo2.textContent = (`Utilizamos cookies propias y de terceros para obtener datos estadísticos de la navegación de nuestros usuarios y mejorar nuestros servicios. Si acepta podrá continuar navegando con una buena experéncia de usuario, si no accepta el uso de cookies el sitio web ${tienda} no estará disponible.`)
    parrafo3.innerHTML = (`Naweblos. Todos los derechos reservados &copy; ${fecha}`);
    botonAceptar.innerHTML = ("Aceptar");
    botonAviso.innerHTML = ("Aviso de cookies");
    //botonRechazar.innerHTML = ("Rechazar y Salir");



    //Añadir atributos

    //imgCookies.setAttribute("src", imagenCookie)
    imgLogo.setAttribute("src", logo)

    //Añadir widget

    divWidget.innerHTML = `
        <svg class="cookies-div__widget-svg">
        <path d="M21.662 2.821C18.866.025 11.663-.252 5.124 5.422-.987 10.725-.89 17.107 3.87 20.613c4.443 3.272 10.542 3.802 15.191-1.256 5.648-6.144 5.399-13.74 2.601-16.536z"></path>


        <path fill="#FFF" d="M8.104 14.644a.567.567 0 01-.804 0h-.001l-2.53-2.529a.57.57 0 01.805-.807l2.128 2.127 6.186-6.185a.57.57 0 01.805.805l-6.589 6.589zm4.895-1.92a.546.546 0 01-.387-.93l4.047-4.047a.549.549 0 01.774 0 .549.549 0 010 .774l-4.046 4.047a.545.545 0 01-.388.156zm4.964 1.236l-1.593 1.591a.544.544 0 01-.773 0 .549.549 0 010-.774l1.594-1.594a.547.547 0 11.79.755l-.016.017-.002.005zm0-2.985l-3.085 3.084a.549.549 0 01-.774-.775l3.087-3.087a.549.549 0 01.774.775l-.002.003z">

        </path>
        </svg> `;



    //comprobar si existe o no la cookie 

    let cookie = document.cookie;
    let cookieExistente = localStorage.getItem("cookies_aceptadas");

    if (!cookieExistente) {

        elementosDom();

    } else {
        widget();
    }

    //Aceptar Aviso Rechazar cookies

    const validar = document.addEventListener('click', e => {

        if (e.target === botonAceptar && comprobar === true) {

            localStorage.setItem("cookies_aceptadas", true)

            divBloqueo.remove();
            widget();

        } else if (e.target === botonAviso) {

            eliminarTodasCookies();
            borrarCookiesLocales();
            location.href = paginaAvisoCookies;
        } else if (e.target === botonAceptar && comprobar === false) {

            eliminarTodasCookies();
            borrarCookiesLocales();
            location.href = url;
         }

    });

    function elementosDom() {

        divImg.appendChild(imgLogo);
        divTexto.appendChild(parrafo1);
        divTexto.appendChild(parrafo2);
        divTexto.appendChild(botonAceptar);
        divTexto.appendChild(botonAviso);
        divTexto.appendChild(parrafo3);
        divCookies.appendChild(divImg);
        divCookies.appendChild(divTexto);
        divBloqueo.appendChild(divCookies);
        divCuerpo.insertAdjacentElement("afterbegin", divBloqueo);

       
    }



    function eliminarTodasCookies() {
        console.log(document.cookie)
        document.cookie.split(";").forEach(function (c) {
            document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
        });
        document.cookie.split(';').forEach(function (c) {
            document.cookie = c.trim().split('=')[0] + '=;' + 'expires=Thu, 01 Jan 1970 00:00:00 UTC;';
        });
        function set_cookie(name, value) {
            document.cookie = name +'='+ value +'; Path=/;';
          }
          function delete_cookie(name) {
            document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
          }
        console.log(document.cookie)
    }


    function borrarCookiesLocales() {

        let locales = Object.keys(window.localStorage);

        for (let i = 0; i < locales.length; i++) {

            let local = locales[i];
            console.log(local);
            localStorage.removeItem(local)

        }
    }

    function widget() {

        //Widget

        divCuerpo.insertAdjacentElement("afterbegin", divWidget);

        const widget = document.querySelector(".cookies-div__widget-svg path");

        widget.addEventListener('click', e => {
             
            if (retirarConsentimiento === true) {

                comprobar = false;
               
                parrafo2.textContent = (`Retirar el consentimiento de Cookies`)
                parrafo2.classList.add("cookies-div__retirar-consentimiento");
                botonAceptar.innerHTML = ("Retirar");
            
                
                elementosDom();
                retirarConsentimiento = false;
            
            } else {
               
                divBloqueo.remove();
                retirarConsentimiento = true;
         
            }

        });

    }
    }, 4000);
      
      



}

iniciarCookies();





